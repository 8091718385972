import styled from 'styled-components';

interface LabelProps {
  readonly $error: boolean;
  readonly $disabled: boolean;
}

export const Root = styled.label<LabelProps>`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.semibold.small.fontSize};
  font-weight: ${(props) => props.theme.font.text.semibold.medium.fontWeight};
  line-height: ${(props) => props.theme.font.text.semibold.medium.lineHeight};
  letter-spacing: ${(props) => props.theme.font.text.semibold.medium.letterSpacing};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  display: block;
  margin-bottom: ${(props) => props.theme.space.small_8};
  user-select: none;

  color: ${(props) => (props.$error ? props.theme.palette.text.error : props.theme.palette.text.secondary)};
  transition: color ${(props) => props.theme.transition.theme};
`;

export const Required = styled.sup`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.light.small.fontSize};
  font-weight: ${(props) => props.theme.font.text.light.small.fontWeight};
  line-height: ${(props) => props.theme.font.text.light.small.lineHeight};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-left: ${(props) => props.theme.space.xs_4};
  transition: color ${(props) => props.theme.transition.theme};
`;
