import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Required, Root } from './InputLabel.styles';
interface Props extends PropsWithClassName {
  children: string;
  forId: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
}
const InputLabel = (props: Props): React.ReactElement => {
  const {
    children,
    forId,
    required = false,
    error = false,
    disabled = false,
    dataTestId
  } = props;
  return <Root $disabled={disabled} htmlFor={forId} className={props.className} data-testid={dataTestId ? dataTestId : 'InputLabel-root'} $error={error} data-sentry-element="Root" data-sentry-component="InputLabel" data-sentry-source-file="InputLabel.tsx">
      {children}
      {required && <Required>*</Required>}
    </Root>;
};
export { InputLabel };