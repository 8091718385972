import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { TextColor, TextSize } from '@Helpers/types/text';
import { Root } from './InputDescription.styles';
interface Props extends PropsWithClassName {
  children: string | React.ReactNode;
}
const InputDescription = (props: Props): React.ReactElement => {
  const {
    children,
    dataTestId
  } = props;
  return <Root size={TextSize.Small} color={TextColor.Tertiary} className={props.className} dataTestId={dataTestId ? dataTestId : 'InputDescription-root'} data-sentry-element="Root" data-sentry-component="InputDescription" data-sentry-source-file="InputDescription.tsx">
      {children}
    </Root>;
};
export { InputDescription };