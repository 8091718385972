import styled from 'styled-components';

import Text from '@Components/atoms/Text';

export const Root = styled(Text)`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.light.small.fontSize};
  font-weight: ${(props) => props.theme.font.text.light.small.fontWeight};
  line-height: ${(props) => props.theme.font.text.light.small.lineHeight};
  letter-spacing: ${(props) => props.theme.font.text.light.small.letterSpacing};
  width: 100%;
  margin-top: ${(props) => props.theme.space.small_8};
`;
