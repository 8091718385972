import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './Form.styles';
interface Props extends PropsWithClassName {
  onSubmit: () => void;
  children: React.ReactNode;
}
const Form = (props: Props): React.ReactElement => {
  const {
    onSubmit,
    children,
    dataTestId
  } = props;
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit();
  };
  return <Root onSubmit={event => handleSubmit(event)} className={props.className} data-testid={dataTestId ? dataTestId : 'Form-root'} data-sentry-element="Root" data-sentry-component="Form" data-sentry-source-file="Form.tsx">
      {children}
    </Root>;
};
export { Form };